<template>
  <v-container>
    <v-row class="justify-space-between">
      <v-col cols="12" sm="12" md="3" class="px-0 col-first">
        <v-btn
          id="goToMain"
          elevation="1"
          color="blue-grey lighten-5"
          min-width="235px"
          height="39px"
          @click.native="goToOrders"
        >
          <span>{{ $t("action.backMain") }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="8" class="px-0 d-flex col-second">
        <v-btn
          v-if="clientNear"
          id="sendToEmail"
          class="mr-md-5"
          elevation="1"
          color="blue-grey lighten-5"
          min-width="235px"
          height="39px"
          @click.native="sendToEmail"
          :loading="loading || protector"
        >
          <span>{{ $t("action.sendToEmail") }}</span>
        </v-btn>
        <v-btn
          id="sendToBank"
          elevation="1"
          color="privat"
          min-width="235px"
          height="39px"
          @click.native="$emit('sendToBank')"
          :loading="sending"
          :disabled="sending || disabledSendToBank || protector"
        >
          <span class="white--text">
            {{
              !authEsa
                ? $t("action.sendToBank")
                : $t("action.sendForProcessing")
            }}
          </span>
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              :size="22"
              :width="2"
              :value="75"
              color="white"
            ></v-progress-circular>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 mt-5">
        <v-card id="iframeCard" class="finally pa-1" v-if="pdf">
          <iframe
            :src="pdf"
            width="100%"
            height="100%"
            id="iframePdf"
          ></iframe>
        </v-card>
        <v-alert v-else dense outlined type="error" max-width="100%">
          {{ $t("error.loadPdf") }}
        </v-alert>
      </v-col>
    </v-row>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";
import { sendOfferToEmail } from "@/services/offer.service";
import { mapGetters } from "vuex";

export default {
  name: "OfferStepFive",
  props: {
    client: Object,
    savedOffer: Object,
    load: {
      type: Boolean,
      default: false
    },
    pdf: String,
    disabledSendToBank: Boolean
  },
  mixins: [showSnack],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters(["getPointOfEntry"]),
    clientNear() {
      return this.client.near;
    },
    sending() {
      return this.load;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    protector() {
      return this.savedOffer?.protector;
    }
  },
  methods: {
    goToOrders() {
      this.$store.dispatch("setOfferStatus", "");
      this.$router.push("/orders");
    },
    sendToEmail() {
      this.loading = true;
      sendOfferToEmail(this.client.cpId, this.authEsa)
        .then(() => {
          this.showSnack("success", [this.$i18n.t("success.sendToEmail")]);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "sendToEmail");

          this.showSnack("error", [this.$i18n.t("error.sendToEmail")]);
          console.log("catch sendToEmail", errorCode);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.col-first,
.col-second {
  padding-bottom: 4px;
}
.col-first {
  .v-btn {
    width: 100%;
  }
}
.col-second {
  justify-content: space-between;
  flex-direction: column;
  .v-btn {
    width: 100%;
    margin-top: 16px;
  }
}

@media (min-width: 600px) {
  .col-first {
    .v-btn {
      width: 40%;
    }
  }
  .col-second {
    flex-direction: row;
    .v-btn {
      width: 40%;
      &:nth-child(2) {
        width: 57%;
      }
    }
  }
}
@media (min-width: 960px) {
  .col-first {
    .v-btn {
      width: auto;
      min-width: 200px !important;
    }
  }
  .col-second {
    justify-content: flex-end;
    .v-btn {
      margin-top: 0;
      width: 250px !important;
      &:nth-child(2) {
        width: 305px !important;
      }
    }
  }
}
@media (min-width: 1264px) {
  .col-first {
    .v-btn {
      width: 270px;
    }
  }
  .col-second {
    justify-content: flex-end;
    .v-btn {
      width: 300px !important;
      &:nth-child(2) {
        width: 350px !important;
      }
    }
  }
}
#iframeCard.finally {
  height: 61.5vh;
  @media (min-width: 960px) {
    height: 66.5vh;
  }
}
</style>
