<template>
  <v-container>
    <v-row class="justify-end hidden-md-only hidden-lg-only hidden-xl-only">
      <v-col
        cols="12"
        sm="7"
        md="5"
        xl="3"
        class="d-flex justify-end pa-0 mt-3 mb-6"
      >
        <v-btn
          id="back"
          class="mr-4"
          elevation="1"
          color="blue-grey lighten-5"
          width="37%"
          height="39px"
          @click.native="$emit('stepBack')"
        >
          <span>{{ $t("action.back") }}</span>
        </v-btn>
        <v-btn
          id="save"
          elevation="1"
          color="privat"
          width="57%"
          height="39px"
          @click.native="$emit('save')"
          :disabled="!pdf"
        >
          <span class="white--text">
            {{ $t("action.save") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row id="bug-alert" v-if="showAlert.show">
      <v-col cols="12">
        <v-alert
          type="error"
          :class="showAlert.class"
          :icon="showAlert.icon"
          outlined
          dense
          max-width="100%"
        >
          <span v-html="showAlert.msg"></span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 mt-md-3">
        <v-card id="iframeCard" class="pa-1" v-if="pdf">
          <iframe
            :src="pdf"
            width="100%"
            height="100%"
            id="iframePdf"
          ></iframe>
        </v-card>
        <v-alert v-else dense outlined type="error" max-width="100%">
          {{ $t("error.other") }} {{ $t("error.loadPdf") }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OfferStepFour",
  props: {
    pdf: String
  },
  data() {
    return {
      showAlert: {
        show: false,
        icon: "mdi-message-alert",
        class: "",
        msg:
          "<span class='font-weight-bold'>Важливо!</span> Наразі, є помилка в розрахунку разової комісії по угоді. <br />" +
          "До завершення технічних правок додатку використовуйте розмір комісії згідно затверджених тарифів. <br />" +
          "При передачі комерційної пропозиції сповістіть про це клієнта!"
      }
    };
  }
};
</script>

<style lang="scss">
#iframeCard {
  height: 69.5vh;
  @media (min-width: 960px) {
    height: 75.5vh;
  }
}
#bug-alert {
  .col.col-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .v-alert {
    max-width: 100%;
  }
  .v-alert__icon {
    margin-top: 10px;
    font-size: 36px;
    &::before {
      color: #ff5252;
    }
  }
}
</style>
