<template>
  <v-container class="px-0 py-5" id="step-3">
    <v-row :class="{ 'mb-1': !financingOptions.length }">
      <v-col>
        <v-alert
          color="blue-grey lighten-5"
          :class="showAlert.class"
          :icon="showAlert.icon"
          prominent
          v-if="showAlert.show"
        >
          {{ showAlert.msg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="financingOptions.length"
      :class="{ 'mb-6': financingOptions.length }"
    >
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="financingOptions"
          item-key="term"
          single-select
          hide-default-footer
          class="mt-2"
          @click:row="selectFinancing"
          style="position: relative"
        >
          <template v-slot:item.sumFunding="{ item }">
            <span>
              {{ (item.totalCost - item.prepaymentSum).toFixed(2) }}
            </span>
          </template>
          <template v-slot:item._prepaymentSumPFReg="{ item }">
            <span v-if="financingCR" class="total-funding">
              {{ item._prepaymentSumPFReg }}
            </span>
            <span v-if="financing579" class="total-funding">
              {{ item._prepaymentSumPFReg }}
              <v-tooltip top max-width="400" min-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="grey"
                    small
                    class="ml-2 mb-1"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <ul class="pa-0">
                  <li class="d-flex">
                    {{ $t("offer.tooltip.includes") }}
                  </li>
                  <li class="d-flex justify-space-between">
                    <span class="pl-3 text--lowercase" style="width: 50%">
                      {{ $t("offer.total.prepayment") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(item.prepaymentSum) }}
                    </span>
                  </li>
                  <li
                    class="d-flex justify-space-between"
                    v-if="nrmOptions.totalReg"
                  >
                    <span class="pl-3" style="width: 70%">
                      {{ $t("offer.total.totalReg") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(nrmOptions.totalReg) }}
                    </span>
                  </li>
                  <li
                    class="d-flex justify-space-between"
                    v-if="nrmOptions.totalPF"
                  >
                    <span class="pl-3" style="width: 70%">
                      {{ $t("offer.total.totalPF") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(nrmOptions.totalPF) }}
                    </span>
                  </li>
                </ul>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:item.totalFunding="{ item }">
            <span class="total-funding">
              {{ item.totalFunding }}
              <v-tooltip top max-width="400" min-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="grey"
                    small
                    class="ml-2 mb-1"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <ul class="pa-0">
                  <li class="d-flex">
                    {{ $t("offer.tooltip.includes") }}
                  </li>
                  <li class="d-flex justify-space-between">
                    <span class="pl-3" style="width: 50%">
                      {{ $t("offer.total.totalCost") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(item.totalCost - item.prepaymentSum) }}
                    </span>
                  </li>
                  <li class="d-flex justify-space-between">
                    <span class="pl-3" style="width: 70%">
                      {{ $t("offer.total.totalReg") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(nrmOptions.totalReg) }}
                    </span>
                  </li>
                  <li
                    class="d-flex justify-space-between"
                    v-if="nrmOptions.totalPF"
                  >
                    <span class="pl-3" style="width: 70%">
                      {{ $t("offer.total.totalPF") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(nrmOptions.totalPF) }}
                    </span>
                  </li>
                </ul>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:item.totalCostPFReg="{ item }">
            <span class="total-cost">
              {{ item.totalCostPFReg }}
              <v-tooltip top max-width="400" min-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="grey"
                    small
                    class="ml-2 mb-1"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <ul class="pa-0">
                  <li class="d-flex">
                    {{ $t("offer.tooltip.includes") }}
                  </li>
                  <li class="d-flex justify-space-between">
                    <span class="pl-3" style="width: 50%">
                      {{ $t("offer.total.totalCost") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(item.totalCost) }}
                    </span>
                  </li>
                  <li class="d-flex justify-space-between">
                    <span class="pl-3" style="width: 70%">
                      {{ $t("offer.total.totalReg") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(nrmOptions.totalReg) }}
                    </span>
                  </li>
                  <li
                    class="d-flex justify-space-between"
                    v-if="nrmOptions.totalPF"
                  >
                    <span class="pl-3" style="width: 70%">
                      {{ $t("offer.total.totalPF") }}
                    </span>
                    <span class="align-self-end">
                      {{ costFilter(nrmOptions.totalPF) }}
                    </span>
                  </li>
                </ul>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:item.prepayment="{ item }">
            <v-btn
              class="showMenu pa-0"
              :text="!item.isSelected"
              :disabled="!item.isSelected"
              :ripple="false"
              depressed
              @click="showMenu = true"
            >
              <span class="prepayment">
                {{ item.prepayment }}% ({{ prepaymentCash(item) }})
                <v-icon color="gray">
                  mdi-menu-down
                </v-icon>
              </span>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="showAutoSolution">
      <v-col cols="12">
        <h4 class="form-subtitle mb-1 d-flex">
          {{ $t("offer.subTitle.autoSolution") }}
          {{ $t(`label.${autoSolution}`) }}
        </h4>
        <template v-if="showKCcomment">
          <span
            v-for="reason in KCcomment"
            :key="reason"
            class="d-block"
            style="font-size: 15px"
          >
            {{ reason }}
          </span>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-wrap"
        :class="showAutoSolution ? 'mt-5' : ''"
      >
        <v-btn
          elevation="1"
          id="back"
          class="mr-4"
          color="blue-grey lighten-5"
          height="39px"
          @click.native="$emit('step', [2, false])"
          v-if="showBtn.includes('BACK')"
        >
          {{ $t("action.back") }}
        </v-btn>
        <v-btn
          elevation="1"
          id="cancel"
          class="mr-4"
          height="39px"
          :color="showBtn.includes('DESIRE') ? 'blue-grey lighten-5' : 'privat'"
          :dark="!showBtn.includes('DESIRE')"
          @click.native="$router.push('/orders')"
          v-if="showBtn.includes('CANCEL')"
        >
          {{ $t("action.complete") }}
        </v-btn>
        <v-btn
          elevation="1"
          id="continue"
          color="privat"
          height="39px"
          dark
          @click.native="sendFinancing"
          v-if="showBtn.includes('FORWARD')"
        >
          {{ $t("action.continue") }}
        </v-btn>
        <v-btn
          elevation="1"
          id="retry"
          class="mr-4"
          color="blue-grey lighten-5"
          height="39px"
          :class="{ 'plus-desire': showBtn.includes('RETRY') }"
          :loading="loading"
          @click.native="goToNrm"
          v-if="showBtn.includes('RETRY')"
        >
          {{ $t("action.retry") }}
        </v-btn>
        <v-btn
          elevation="1"
          id="desire"
          color="privat"
          height="39px"
          dark
          :class="{ 'plus-retry': showBtn.includes('RETRY') }"
          @click.native="goToPdf"
          v-if="showBtn.includes('DESIRE')"
        >
          {{ $t("action.desire") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showMenu" persistent max-width="250px">
      <v-card class="pa-3">
        <v-card-text class="px-2 pb-0 mt-4">
          <v-select
            v-model="desiredPrePay"
            id="desiredPrePay"
            :items="prePayOptions"
            item-text="value"
            item-value="value"
            :label="$i18n.t('label.prepayment')"
            :disabled="loaderGetRate"
            color="privat"
            autofocus
            required
            outlined
            dense
          ></v-select>
        </v-card-text>
        <v-card-actions class="justify-space-between pt-0">
          <v-btn
            id="close"
            color="gray"
            @click="closeMenu"
            width="45%"
            :disabled="loaderGetRate"
          >
            {{ $t("action.cancel") }}
          </v-btn>
          <v-btn
            id="ok"
            color="privat"
            @click="savePrePay"
            width="45%"
            dark
            :loading="loaderGetRate"
          >
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import costFilter from "@/filters/cost.filter";
import * as offerService from "@/services/offer.service";
import { mapGetters } from "vuex";
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";

export default {
  name: "OfferStepThree",
  mixins: [showSnack],
  props: {
    nrmOptions: {
      type: Object,
      default: () => ({})
    },
    general: {
      type: Object,
      default: () => ({})
    },
    cpId: String,
    program: String
  },
  data() {
    return {
      selected: [{ minPrePayment: 10 }],
      financingOptions: [],
      prePayments: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
      desiredPrePay: null,
      showMenu: false,
      rate: null,
      showAlert: {
        show: false,
        icon: "mdi-bank",
        class: "",
        msg: ""
      },
      showBtn: [],
      loading: false,
      costFormat: "uk-UA",
      loaderGetRate: false,
      autoSolution: null,
      KCcomment: []
    };
  },
  computed: {
    ...mapGetters(["getPointOfEntry"]),
    financing579() {
      return this.program === "FL_579";
    },
    financingCR() {
      return this.program.includes("CR");
    },
    headers() {
      if (this.financing579 || this.financingCR) {
        return offerService.setNRMTableHeadersFinancing759.call(this);
      } else {
        return offerService.setNRMTableHeaders.call(this);
      }
    },
    prePayOptions() {
      let percent = this.selected[0].minPrePayment;

      return this.prePayments.filter(el => {
        if (el === percent || el > percent) {
          return el;
        }
      });
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    showAutoSolution() {
      return this.authEsa && this.autoSolution !== null;
    },
    showKCcomment() {
      return !this.autoSolution && this.KCcomment.length;
    }
  },
  methods: {
    costFilter: costFilter,
    selectFinancing(val) {
      this.financingOptions.forEach(item => {
        item.isSelected = item.term === val.term;
      });
      this.rate = val.rate;
      this.selected = [val];
      this.desiredPrePay = val.prepayment;
    },
    savePrePay() {
      this.loaderGetRate = true;
      this.getRate();
    },
    closeMenu() {
      this.desiredPrePay = this.selected[0].prepayment;
      this.showMenu = false;
    },
    sendFinancing() {
      let percent = this.selected[0].prepayment;
      let term = this.selected[0].monthCount;

      this.$emit("progress", true);
      this.$emit("update", { percent: percent, term: term });
    },
    prepaymentCash(item) {
      let cost = this.nrmOptions.cost;

      return ((cost / 100) * item.prepayment).toFixed(2);
    },
    createProps() {
      let decision = this.nrmOptions.decision;
      let preferred = this.nrmOptions.preferredPercent;
      let bankMin = this.nrmOptions.bankMinPercent;

      this.autoSolution = this.nrmOptions?.autoSolution || null;
      this.KCcomment = this.nrmOptions?.KCcomment || [];
      this.showBtn = this.nrmOptions.buttons;
      // this.showBtn = ["BACK", "RETRY", "CANCEL", "DESIRE"]; // mock
      if (decision !== "APPROVED") {
        this.showAlert.msg = this.nrmOptions.comment;
        if (decision === "DECLINE") {
          this.showAlert.class = "decline";
          this.showAlert.icon = "mdi-alert-circle";
        } else {
          this.showAlert.class = "needChoice";
        }
        this.showAlert.show = true;
      } else {
        if (preferred < bankMin) {
          let program = this.financingCR
            ? this.$i18n.t("offer.message.program.CR")
            : this.$i18n.t("offer.message.program.FL");

          this.showAlert.show = true;
          this.showAlert.msg = this.$i18n.t("offer.message.NRM", {
            program: program,
            percent: preferred
          });
        }
      }
      // this.showAlert.class = "decline"; // mock
      // this.showAlert.icon = "mdi-alert-circle"; // mock
    },
    createDataTable() {
      let list = this.nrmOptions.possibilityList;

      this.financingOptions = list.map(el => {
        let totalFunding = this.nrmOptions.totalCostPFReg - el.prepaymentSum;
        let rake = `${el.commissionPercent} % (${el.commissionSum.toFixed(2)})`;

        return Object.assign(el, {
          term: `${el.monthCount} мес`,
          prepayment: el.prepaymentPercent,
          totalCost: this.nrmOptions.cost,
          totalCostPFReg: this.nrmOptions.totalCostPFReg.toFixed(2),
          minPrePayment: el.prepaymentPercent,
          isSelected: false,
          rate: `${el.stake} %`,
          totalFunding: totalFunding.toFixed(2),
          onceCommission: rake,
          _prepaymentSumPFReg: el.prepaymentSumPFReg.toFixed(2)
        });
      });
      this.financingOptions[0].isSelected = true;
    },
    getRate() {
      let selectedRow = this.selected[0];
      let percent = this.desiredPrePay;
      let term = selectedRow.monthCount;

      offerService
        .getRate(this.cpId, percent, term, this.authEsa)
        .then(res => {
          this.updateSelectedRow(res.data, selectedRow);
          this.showMenu = false;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getRateStepThree");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getRent3 - ", errorCode);
        })
        .finally(() => {
          this.loaderGetRate = false;
        });
    },
    updateSelectedRow(data, row) {
      row.prepayment = this.desiredPrePay;
      row.prepaymentSum = ((row.totalCost / 100) * row.prepayment).toFixed(2);

      const costPFReg = row.totalCostPFReg - row.totalCost;
      const sumPFReg = (row.totalCost / 100) * row.prepayment;

      row._prepaymentSumPFReg = (sumPFReg + costPFReg).toFixed(2);

      this.financingOptions = this.financingOptions.map(el => {
        if (el.isSelected) {
          el.rate = `${data.interestRate} %`;
          el.totalFunding = (el.totalCostPFReg - el.prepaymentSum).toFixed(2);
          el.onceCommission = `${
            data.prcComm
          } % (${data.onceCommissionSum.toFixed(2)})`;
        }

        return el;
      });
    },
    goToPdf() {
      this.$emit("progress", true);
      this.$emit("goToPdf", { desire: true });
    },
    goToNrm() {
      this.$emit("progress", true);
      this.$emit("goToNrm");
    }
  },
  mounted() {
    this.rate = this.general.rate;
    this.createProps();
    if (this.nrmOptions.possibilityList) {
      this.createDataTable();
      this.selectFinancing(this.financingOptions[0]);
    }
  }
};
</script>

<style lang="scss">
#form-offer {
  .v-alert {
    max-width: 650px;
    font-size: 15px;
    &--prominent {
      .v-alert__icon {
        height: 32px;
        min-width: 32px;
        margin-left: 10px;
        margin-right: 22px;
        &.v-icon {
          font-size: 24px;
        }
        &::before {
          z-index: 9999;
          color: #fff;
          margin-left: 1px;
          margin-bottom: 1px;
        }
        &::after {
          background-color: #addfb4 !important;
          opacity: 1 !important;
        }
      }
    }
    &--prominent.needChoice {
      .v-alert__icon {
        &::after {
          background-color: #ffc400 !important;
        }
      }
    }
    &--prominent.decline {
      .v-alert__icon {
        &.v-icon {
          font-size: 45px;
        }
        &::before {
          color: #d32f2f;
        }
        &::after {
          background-color: #fff !important;
        }
      }
    }
  }
  .v-data-table {
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    &-header {
      .text-start {
        font-size: 14px;
      }
    }
    &__selected {
      background-color: #f5fff1;
      box-shadow: inset 0 0 0 2px var(--color-privat);
      border-radius: 4px;
      .showMenu {
        background-color: #f5fff1;
      }
      .v-data-table__mobile-row {
        @media (max-width: 599.9px) {
          background: #f5fff1;
          border-left: 2.5px solid var(--color-privat);
          border-right: 2.5px solid var(--color-privat);
          &:first-child {
            border-top: 2.5px solid var(--color-privat);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
          &:last-child {
            border-bottom: 2.5px solid var(--color-privat);
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
    &-header-mobile tr th {
      @media (max-width: 599.9px) {
        height: 2px;
        border-bottom: none;
      }
    }
    table > tbody > tr > td {
      border-bottom: none;
    }
  }
  .theme--light.v-btn.v-btn--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .showMenu {
    &::before {
      content: none;
    }
  }
  .total-funding {
    width: 110px;
  }
  .total-cost {
    width: 115px;
  }
  .prepayment {
    min-width: 175px;
  }
  .total-funding,
  .total-cost,
  .prepayment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 599px) {
      justify-content: end;
    }
  }
  .total-funding,
  .total-cost {
    .v-icon {
      @media (max-width: 599px) {
        margin-right: 5px;
      }
    }
  }
}
#step-3 {
  .v-btn {
    padding: 0 35px;
    &#cancel + #desire {
      @media (max-width: 588px) {
        margin-top: 15px;
      }
    }
    @media (max-width: 400px) {
      padding: 0 25px;
    }
  }
  #retry {
    &.plus-desire {
      @media (max-width: 473px) {
        margin-top: 15px;
      }
    }
  }
  #desire {
    &.plus-retry {
      @media (max-width: 852px) {
        margin-top: 15px;
      }
    }
    @media (max-width: 404px) {
      white-space: normal;
      display: inline-block;
      max-width: 295px;
      height: 50px !important;
    }
  }
}
</style>
